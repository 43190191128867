import React from "react";
import { useTranslation } from "react-i18next";

export default function AdsSection() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="vc_row row_padding_top full" data-bgcolor="#eee">
        <div className="content-marquee-wrapper">
          <div
            className="content-marquee"
            data-text="Enabling Digital renovation to make your business operation easier."
          >
            {t(
              "Enabling Digital renovation to make your business operation easier."
            )}
          </div>
        </div>
      </div>
    </>
  );
}
