import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import { CONTACT_US } from "../Home.Api";
export default function ContactUs() {
  const { t, i18n } = useTranslation();
  const [Values, setValues] = useState({
    Name: "",
    Email: "",
    PhoneNumber: "",
    Reason: "",
    Message: "",
  });

  const HandleChange = useCallback((e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);
  const OnSubmit = (e) => {
    // e.preventDefualt();
    CONTACT_US(Values)
      .then((res) => {
        notify(
          "Message Send Successfully we will connect with you as soon as possible",
          "success",
          3000
        );
        setValues({
          Name: "",
          Email: "",
          PhoneNumber: "",
          Reason: "",
          Message: "",
        });
      })
      .catch(() => {
        notify("Error in information. try again! ", "error", 3000);
      });
  };
  return (
    <>
      {" "}
      <div
        className="vc_row row_padding_top row_padding_bottom small white-section change-header-color"
        data-bgcolor="#fff"
        id="contact"
      >
        <h5 className="has-mask no-margins">Get in Touch</h5>
        <h1 className="has-mask" data-delay={150}>
          <b> {t("Contact Custom Solutions")}</b>
        </h1>
        <hr />
        {/* Contact Formular */}
        <div id="contact-formular">
          <div id="message" />
          <form name="contactform" id="contactform" onSubmit={OnSubmit}>
            <div className="name-box has-animation" data-delay={100}>
              <input
                name="Name"
                type="text"
                id="name"
                size={30}
                value={Values["Name"]}
                onChange={HandleChange}
                placeholder={t("What's Your Name")}
              />
              <label className="input_label" />
            </div>
            <div className="email-box has-animation" data-delay={150}>
              <input
                name="Email"
                type="text"
                id="email"
                onChange={HandleChange}
                size={30}
                value={Values["Email"]}
                placeholder={t("Your Email")}
              />
              <label className="input_label" />
            </div>
            <div className="message-box has-animation" data-delay={100}>
              <textarea
                name="Message"
                cols={40}
                rows={4}
                id="comments"
                onChange={HandleChange}
                value={Values["Message"]}
                placeholder={t("Tell Us About Your Project")}
                defaultValue={""}
              />
              <label className="input_label slow" />
            </div>
            <div className="button-box has-animation" data-delay={100}>
              <div className="shetewytech-button-wrap circle parallax-wrap bigger">
                <div className="shetewy-button parallax-element">
                  <div className="button-border  outline parallax-element-second">
                    <input
                      type="button"
                      className="send_message"
                      id="submit"
                      defaultValue="Submit"
                      onClick={OnSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/*/Contact Formular */}
      </div>
    </>
  );
}
