import REQUEST from "../../services/Request";

export const HOME_DATA = async () => {
  return await REQUEST({
    url: "Home/All",
    method: "GET",
  });
};
export const CONTACT_US = async (e) => {
  return await REQUEST({
    method: "post",
    url: "ContactUs",
    data: e,
  });
};
