import React from "react";
import video from "../../../assets/avrg.mp4";
import { useTranslation } from "react-i18next";
export default function Header({ data }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div id="hero" className="has-image autoscroll">
        <div id="hero-styles">
          {data.map((ele) => (
            <>
              <div
                id="hero-caption"
                className="reverse-parallax-onscroll text-center"
              >
                <div className="inner text-center">
                  <h2 className="text-center " style={{ color: "#fbff05" }}>
                    {i18n.language === "en" ? ele.TitleEn : ele.Title}
                  </h2>
                  <h4 className="text-center">
                    {i18n.language === "en" ? ele.ContentEn : ele.Content}
                  </h4>
                  <br /> <img src="herofooterdark.png" alt="" />
                </div>
              </div>
            </>
          ))}

          <div id="hero-footer">
            <div className="hero-footer-left">
              <div className="button-wrap left disable-drag scroll-down">
                <div className="icon-wrap parallax-wrap">
                  <div className="button-icon parallax-element">
                    <i className="fa fa-angle-down" />
                  </div>
                </div>
                <div className="button-text sticky left">
                  <span data-hover={t("Scroll or drag to navigate")}>
                    {t("Scroll or drag to navigate")}
                  </span>
                </div>
              </div>
            </div>

            <div className="hero-footer-right">
              <div
                id="share"
                class="page-action-content disable-drag jssocials"
                data-text="Share:"
              >
                <div class="jssocials-shares">
                  <div class="parallax-wrap">
                    <div class="parallax-element">
                      <div class="jssocials-share jssocials-share-facebook">
                        <a
                          target="_blank"
                          href="https://facebook.com/sharer/sharer.php?u=http%3A%2F%2F127.0.0.1%3A5500%2Findex.html"
                          class="jssocials-share-link"
                        >
                          <i class="fab fa-facebook-f jssocials-share-logo"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    class="parallax-wrap"
                    style={{ transform: " translate(0px, 0px)" }}
                  >
                    <div
                      class="parallax-element"
                      style={{ transform: " translate(0px, 0px)" }}
                    >
                      <div class="jssocials-share jssocials-share-twitter">
                        <a
                          target="_blank"
                          href="https://twitter.com/share?url=http%3A%2F%2F127.0.0.1%3A5500%2Findex.html&amp;text=GSA%20Analytics%20Website"
                          class="jssocials-share-link"
                        >
                          <i class="fab fa-twitter jssocials-share-logo"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="parallax-wrap">
                    <div class="parallax-element">
                      <div class="jssocials-share jssocials-share-pinterest">
                        <a
                          target="_blank"
                          href="https://pinterest.com/pin/create/bookmarklet/?&amp;url=http%3A%2F%2F127.0.0.1%3A5500%2Findex.html&amp;description=GSA%20Analytics%20Website"
                          class="jssocials-share-link"
                        >
                          <i class="fab fa-youtube jssocials-share-logo"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div id="hero-image-wrapper">
        <div id="hero-background-layer" className="parallax-scroll-effect">
          <div
            id="hero-bg-image"
            style={{ backgroundImage: "url(images/04hero.jpg)" }}
          >
            <div className="hero-video-wrapper">
              <video loop muted className="bgvid">
                <source src={video} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
