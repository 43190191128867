import React from "react";
import { useTranslation } from "react-i18next";

export default function WebLoading() {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="preloader-wrap"
      data-firstline="Enjoy"
      data-secondline="Custom Solutions"
    >
      <div className="outer">
        <div className="inner">
          <div className="trackbar">
            <div className="loadbar" />
          </div>
          <div className="percentage-wrapper">
            <div className="percentage" id="precent" />
          </div>
          <div className="percentage-intro">
            {t("Loading Custom Solutions Experience")}
          </div>
        </div>
      </div>
    </div>
  );
}
